import React, {Component} from "react";
import ResearchStore from "../../../Stores/NavModules/NavResearch/ResearchStore.js";
export default class NavResearch extends Component {
  constructor(props) {
    super(props);
    this.ResearchStoreStateChange = this.ResearchStoreStateChange.bind(this);
    this.state = ResearchStore.getState();
  }
  componentDidMount() {
    ResearchStore.addChangeListener(this.ResearchStoreStateChange);
  }

  componentWillUnmount() {
    ResearchStore.removeChangeListener(this.ResearchStoreStateChange);
  }

  ResearchStoreStateChange() {
    let state = ResearchStore.getState();
    this.setState(state);
  }


  render() {
    let url;
    
    url = this.state.tabUrl !='' ? this.state.tabUrl : this.state.url;

    if(this.state.tabUrl)
      setTimeout(() => {
        ResearchStore.updateRipanelUrl('', false);
      }, 500);

    return (
      <div className="listConsole">
        <div className="extender">
          <div className="COLS research-dashboard">
            <div className="list-view-area">
              <iframe className="research-iframe" title="research" ref={(ref)=> this.frame = ref} src={url} allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
